<template>
  <b-container class="mt-5 pt-5">
    <b-row class="justify-content-center h-100 bg-white pb-5">
      <b-col
        cols="12"
        md="6"
      >
        <b-row>
          <!-- Tanggal -->
          <b-col
            cols="12"
            md="12"
            class="mb-2 custom__form--input"
          >
            <label for="v-nama-gudang">Tanggal <span class="text-danger">*</span></label>
            <b-input-group class="input-group-merge" @submit.stop.prevent>
              <flat-pickr
                v-model="formPayloadOthers.date"
                class="form-control"
              />
              <b-input-group-append is-text>
                <b-img :src="require('@/assets/images/icons/calendar.svg')" alt="dateIcon" />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="messages.date && Array.isArray(messages.date) && messages.date.length > 0"
              class="text-danger"
            >{{ messages.date.join(', ') }}</small>
          </b-col>
          <!-- Tipe Pembayaran -->
          <b-col
            cols="12"
            md="6"
            class="mb-2 custom__form--input"
          >
            <label for="v-nama-alias">Tipe Pembayaran <span class="text-danger">*</span></label>
            <b-form-group class="mb-0">
              <v-select
                v-model="formPayloadOthers.tipe_pembayaran"
                label="title"
                :options="optionsTypePayment"
                class="border border-8"
                @input="changeTypePembayaran($event)"
              />
            </b-form-group>
            <small
              v-if="messages.tipe_pembayaran && Array.isArray(messages.tipe_pembayaran) && messages.tipe_pembayaran.length > 0"
              class="text-danger"
            >{{ messages.tipe_pembayaran.join(', ') }}</small>
          </b-col>
          <!-- Jenis Pembayaran -->
          <b-col
            cols="12"
            md="6"
            class="mb-2 custom__form--input"
          >
            <label for="v-nama-alias">Jenis Pembayaran <span class="text-danger">*</span></label>
            <b-form-group class="mb-0">
              <v-select
                v-model="formPayloadOthers.jenis_pembayaran"
                label="title"
                :options="optionsKindPayment"
                class="border border-8"
              />
            </b-form-group>
            <small
              v-if="messages.jenis_pembayaran && Array.isArray(messages.jenis_pembayaran) && messages.jenis_pembayaran.length > 0"
              class="text-danger"
            >{{ messages.jenis_pembayaran.join(', ') }}</small>
          </b-col>
          <!-- Detail Pembayaran -->
          <b-col
            cols="12"
            md="12"
            class="mb-2 custom__form--input"
          >
            <label for="v-detail">Detail Pembayaran</label>
            <b-form-group>
              <b-form-textarea
                id="v-detail"
                v-model="formPayloadOthers.detail"
                rows="3"
                value=""
                placeholder="Masukkan Detail Pembayaran"
                class="custom__textarea border-8"
              />
            </b-form-group>
          </b-col>
          <!-- Penerima -->
          <b-col md="6" class="mb-2 custom__form--input">
            <label for="v-penarima">Penerima <span class="text-danger">*</span></label>
            <b-form-group>
              <b-form-input
                id="v-penarima"
                v-model="formPayloadOthers.receiver"
                value=""
                placeholder="Masukkan Nama Penerima"
                class="custom__input border-8"
              />
            </b-form-group>
            <small
              v-if="messages.receiver && Array.isArray(messages.receiver) && messages.receiver.length > 0"
              class="text-danger"
            >{{ messages.receiver.join(', ') }}</small>
          </b-col>
          <!-- No Invoice / SPT -->
          <b-col md="6" class="mb-2 custom__form--input">
            <label for="v-invoice">No Invoice / SPT <span class="text-danger">*</span></label>
            <b-form-group>
              <b-form-input
                id="v-invoice"
                v-model="formPayloadOthers.invoice_number_spt"
                rows="3"
                value=""
                placeholder="Masukkan Nomor Invoice / SPT"
                class="custom__input border-8"
              />
            </b-form-group>
            <small
              v-if="messages.invoice_number_spt && Array.isArray(messages.invoice_number_spt) && messages.invoice_number_spt.length > 0"
              class="text-danger"
            >{{ messages.invoice_number_spt.join(', ') }}</small>
          </b-col>
          <!-- Jumlah Nilai Pembayaran -->
          <b-col md="12" class="mb-2 custom__form--input">
            <label for="v-jumlah-pembayaran">Jumlah Nilai Pembayaran <span class="text-danger">*</span></label>
            <b-form-group>
              <money
                id="v-jumlah-pembayaran"
                v-model="formPayloadOthers.amount"
                v-bind="money"
                value=""
                placeholder="Masukkan Nilai Pembayaran"
                class="custom__input border-8 pl-1 w-100"
              />
            </b-form-group>
            <small
              v-if="messages.amount && Array.isArray(messages.amount) && messages.amount.length > 0"
              class="text-danger"
            >{{ messages.amount.join(', ') }}</small>
          </b-col>
          <!-- Bukti Pembayaran -->
          <b-col md="12" class="mb-2 custom__form--input">
            <label for="v-jumlah-pembayaran">Bukti Pembayaran</label>
            <b-form-group>
              <b-form-file
                v-model="formPayloadOthers.file_attachment"
                accept="image/*"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                class="border-8 bg-primary"
              />
            </b-form-group>
            <small
              v-if="messages.file_attachment && Array.isArray(messages.file_attachment) && messages.file_attachment.length > 0"
              class="text-danger"
            >{{ messages.file_attachment.join(', ') }}</small>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {
  BContainer, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BImg, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    vSelect,
    flatPickr,
    BFormFile,
  },
  props: {
    messages: {
      type: ''
    },
  },
  watch: {
    formPayloadOthers: {
      handler(value) {
        this.$store.commit('payout/setFormPayloadOthers', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      selected: 'Paling Terkahir',
      options: ['Paling Terakhir', 'Pertama'],
      selectedHari: 'Hari',
      optionsDay: ['Hari'],
      checkTanggal: false,
      checkStatus: false,
      pelanggan: {
        is_show: false,
        options: ['Ritel', 'Non-Ritel'],
        checkboxValue: 'Ritel',
      },
      optionsTypePayment: [
        'Operational',
        'Aset',
        'Pengambilan Dana Oleh Pemilik',
        'Pembayaran Pajak',
      ],
      optionsKindPayment: [],
      rangeDate: null,
      formPayloadOthers: {
        date: '',
        jenis_pembayaran: '',
        tipe_pembayaran: '',
        detail: '',
        receiver: '',
        invoice_number_spt: '',
        amount: 0,
        payment_method: '',
        file_attachment: [],
      },
    }
  },
  methods: {
    changeTypePembayaran(event) {
      switch (event) {
        case 'Operational':
          this.optionsKindPayment = ['Gaji Karyawan', 'Operasional Delivery', 'Operasional Toko', 'Lainnya']
          this.formPayloadOthers.jenis_pembayaran = ''
          break
        case 'Aset':
          this.optionsKindPayment = ['Pembelian Kendaraan', 'Pembelian Perlengkapan Toko']
          this.formPayloadOthers.jenis_pembayaran = ''
          break
        case 'Pengambilan Dana Oleh Pemilik':
          this.optionsKindPayment = ['Pengambilan Dana Oleh Pemilik']
          this.formPayloadOthers.jenis_pembayaran = ''
          break
        case 'Pembayaran Pajak':
          this.optionsKindPayment = ['PPN', 'PBB', 'PPh']
          this.formPayloadOthers.jenis_pembayaran = ''
          break
        default:
          break
      }
    },
    getPhoto(value) {
      // eslint-disable-next-line no-console
      console.log(value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@/assets/scss/variables/_variables.scss';
.flatpickr-input {
  background-color: #F1F2F5 !important;
  border-radius: 8px;
  height: 52px;
  border: none;
}
.input-group-text {
  background-color: #F1F2F5 !important;
  border-radius: 8px;
  border: none;
}
.b-form-file {
  .custom-file-label {
    height: 53px !important;
  }
}
.custom-file-label {
  background-color: #F1F2F5 !important;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;

  &::after {
    background-color: #F1F2F5 !important;
    height: 53px;
    display: flex;
    align-items: center;
  }
}
</style>
